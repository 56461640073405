div.menu {
    display: flex;
}

.menuItem {
    margin-left: 2em;
}

.menuItem a {
    text-decoration: none;
    font-size: 1.5em;
    color: wheat;
}