.languageList {
    padding: 1rem;
    margin: auto;
    text-align: center;
    display: grid;
    gap: 2rem;
    list-style: none;
}

@media (min-width: 450px) {
    .languageList {
        grid-template-columns: repeat(4,25%);
        max-width: 60rem;
    }
}