.courseResume {
    width: 27em;
    max-width: 40em;
    margin-right: 1em;
    margin-top: 2em;
    padding: 2em;
    border-radius: 8px;
    background-color: darkred;
    color: white;
    cursor: pointer;
}
