.header {
    padding: 1rem;
    max-width: 60rem;
    margin: auto auto 2rem;
    text-align: center;
}

.header h2 {
    font-size: 2rem;
    line-height: 1.5em;
}

.headerImage{
    padding: 1rem;
    margin: auto auto 2rem;
}